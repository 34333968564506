
import {orderFormInit} from './_calc_form.js';

orderFormInit();

(function ($) {
 	$(document).ready(function () {	
		
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})
		
//Окна вызова мастера-обратного звонка

		
		$('.view-main-cat .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.views-field-title a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})	
		
		$('.view-prime-menu- .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.view-09-door-variety .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.view-mainpagecat .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.view-doughterteasers .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.view-sorter-doors-result .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.search-results li.search-result').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.view-sisterteasers .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
		$('.view-extra-cat-door-list .views-row').each(function(index,elem) {
			$(elem).click(function(e){
				var linkElem=$(this).find('.node>h2 a');
				var pageHref=linkElem.attr('href');
				document.location.href=pageHref;
			})
		})
		
// Здесь первый конец
	});
// Здесь полный конец
})(jQuery);		