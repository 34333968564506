
export function orderFormInit() {
	
	//console.log('num3');
	
jQuery(document).ready(function() {		
	
	jQuery('#block-detachedcalc-hello-message #edit-printbt').bind(
		'click', function(e){
			e.preventDefault();
			window.print();
	});
	jQuery('#block-detachedcalc-hello-message #edit-orderbt').bind(
		'click', function(e){
			e.preventDefault();
			document.location.href='/contacts.html';
	});	
	
	//console
	
	//jQuery('#admin-menu .dropdown').css('z-index','1060');
	
})
}




